import { FeatureCheckerService, PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { UserServiceProxy } from '@shared/service-proxies/service-proxies';

@Injectable()
export class AppNavigationService {
    public visibilitaSubMenuAdmin = false;

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService,
        private featureService: FeatureCheckerService
    ) {

    }

    getMenu(menuLevel = 0): AppMenu {        
        if (menuLevel == 1) {
            return this.getAppMenu1();
        } else if (menuLevel == 2) {
            return this.getAppMenu2();
        } else {
            return this.getAppMenu();
        }
    }

    getAppMenu1(): AppMenu {
        let menu = new AppMenu('menuLevel1', 'menuLevel1', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.AgencyMaster.Dashboard', 'flaticon-line-graph', '/app/main/dashboardMaster'),
            new AppMenuItem('Reservations', 'Pages.Reservation.List', 'flaticon-event-calendar-symbol', '/app/main/reservations'),
            new AppMenuItem('Flight', 'Pages.Flight', 'fa fa-plane', '/app/flight'),
            new AppMenuItem('Hotel', 'Pages.Hotel', 'fa fa-hotel', '/app/hotel'),
            new AppMenuItem('Train', 'Pages.Train', 'fa fa-train', '/app/train'),
            new AppMenuItem('Car', 'Pages.Car', 'fa fa-car', '/app/car'),
            new AppMenuItem('Carnet', 'Pages.Carnet.Search', 'fa fa-credit-card', '/app/carnet'),
            new AppMenuItem('Administration', '', 'fa fa-bars', '', [], [], true),
        ]);
        return menu;
    }
    getAppMenu2(): AppMenu {
        let menu = new AppMenu('menuLevel2', 'menuLevel2', [
            new AppMenuItem('Agency_Dashboard', 'Pages.Agency.Dashboard', 'flaticon-profile-1', '/app/main/agencyAdmin/dashboardAgency'),
            new AppMenuItem('Reports', 'Pages.Reports', 'fa fa-download', '', [], [
                new AppMenuItem('Reservations_ReportsSegmentsProduction', 'Pages.Reports.SegmentsProduction', 'fa fa-plane', '/app/main/reports/segmentsProduction'),
                new AppMenuItem('Reservations_ReportsAnalytics', 'Pages.Reports.Analytics.Dashboard', 'fa fa-plane', '/app/main/reports/analytics')
            ], true),
            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [], [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Agencies', 'Pages.Administration.Agencies', 'flaticon-profile-1', '/app/admin/agency/agencies'),
                new AppMenuItem('Agency_Logo', 'Pages.Agency.ManageLogo', 'fa fa-file-image', '/app/admin/agency/manage-logo'),
                new AppMenuItem('Fee_List_Management', 'Pages.Fee', 'flaticon2-zig-zag-line-sign', '/app/main/agencyAdmin/fee'),
                new AppMenuItem('AgencyFee_List_Management', 'Pages.AgencyFee', 'flaticon-settings', '/app/main/agencyAdmin/agencyFee'),
                new AppMenuItem('Train_Settings', 'Pages.Train.Settings', 'flaticon-settings', '/app/admin/train-settings'),
                
                new AppMenuItem('AirlineRoutes', 'Pages.TravelFusionAirlineRoutes', 'flaticon-add-label-button', '/app/admin/airline-routes'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                new AppMenuItem('UserProfile_CustomFields', 'Pages.UserProfiles.CustomFields', 'fas fa-user-edit' , '/app/user-profile/custom-fields'),
                new AppMenuItem('Trip_CustomFields', 'Pages.Trips.CustomFields', 'fas fa-user-edit' , '/app/trip/custom-fields'),
                new AppMenuItem('Agency_PaymentProfiles', 'Pages.Administration.PaymentProfiles', 'fa fa-credit-card', '/app/admin/payment-profiles'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
                
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('EventSync', 'Pages.Administration.EventSync', 'flaticon-refresh', '/app/admin/eventSync'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
                
                new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'flaticon-interface-8', '/app/admin/dynamic-property'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings'),
                // new AppMenuItem('ProviderSettings', 'Pages.Administration.Tenant.ProviderSettings', 'flaticon-settings', '/app/admin/providerSettings'),
                new AppMenuItem('ProviderProfiles', 'Pages.Administration.Host.ProviderProfiles', 'flaticon-settings', '/app/admin/providerProfiles'),
                new AppMenuItem('TrainSettings', 'Pages.Tenants', 'flaticon-settings', '/app/admin/train-settings'),
                new AppMenuItem('Agreement_Settings', 'Pages.Tenants', 'flaticon-settings', '/app/admin/agreements'),
                new AppMenuItem('SCIMToken_Settings', 'Pages.Tenants', 'flaticon-settings', '/app/admin/scim-token-list'),

                new AppMenuItem('ManagementFeedAccountant', 'Pages.FeedAccountantSettings', 'flaticon-settings', '/app/admin/feedAccountantSettings'),
                
                new AppMenuItem('AppSettings', 'Pages.AppSettings', 'flaticon-settings', '/app/admin/AppSettings')
            ], true),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('Airports', 'Pages.Administration.Host.Entities.Airports', 'fa fa-plane', '/app/admin/airports'),
            new AppMenuItem('Aircrafts', 'Pages.Administration.Host.Entities.Aircrafts', 'fa fa-plane', '/app/admin/aircrafts'),
            new AppMenuItem('Airlines', 'Pages.Administration.Host.Entities.Airlines', 'fa fa-plane', '/app/admin/airlines'),
            new AppMenuItem('Stations', 'Pages.Administration.Host.Entities.Stations', 'fa fa-train', '/app/admin/stations'),
            new AppMenuItem('RegionRoot', 'Pages.Administration.Host.Entities.RegionRoots', 'fa fa-building', '/app/admin/regionroots'),
            new AppMenuItem('StructureRoot', 'Pages.Administration.Host.Entities.StructureRoots', 'fa fa-building', '/app/admin/structureroots'),
            new AppMenuItem('CarLocations', 'Pages.Administration.Host.Entities.CarLocations', 'fa fa-car', '/app/admin/carlocations'),
        ]);
        let menuItem = menu.items.find(x => x.name === 'Administration');
        if (this.featureService.isEnabled('App.UserProfiles') && this._permissionCheckerService.isGranted('Pages.UserProfile')) {
            menuItem.items.splice(menuItem.items.length - 2 >= 0 ? menuItem.items.length - 2 : 0, 0, new AppMenuItem('UserProfiles', 'Pages.UserProfile', 'flaticon-users', '/app/main/user-profiles'));
        }
        if (this.featureService.isEnabled('App.Flight') && this._permissionCheckerService.isGranted('Pages.ValidatingCarrier')) {
            menuItem.items.splice(menuItem.items.length > 4 ? 4 : menuItem.items.length, 0, new AppMenuItem('ValidatingCarrier', 'Pages.ValidatingCarrier', 'fa fa-plane', '/app/admin/validating-carriers'));
        }
        return menu;
    }

    getAppMenu(): AppMenu {
        let menu = new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Dashboard', 'Pages.AgencyMaster.Dashboard', 'flaticon-line-graph', '/app/main/dashboardMaster'),
            new AppMenuItem('Agency_Dashboard', 'Pages.Agency.Dashboard', 'flaticon-profile-1', '/app/main/agencyAdmin/dashboardAgency'),
            new AppMenuItem('Reservations', 'Pages.Reservation.List', 'flaticon-event-calendar-symbol', '/app/main/reservations'),
            new AppMenuItem('Reports', 'Pages.Reports', 'fa fa-download', '', [], [
                new AppMenuItem('Reservations_ReportsSegmentsProduction', 'Pages.Reports.SegmentsProduction', 'fa fa-plane', '/app/main/reports/segmentsProduction'),
                new AppMenuItem('Reservations_ReportsAnalytics', 'Pages.Reports.Analytics.Dashboard', 'fa fa-plane', '/app/main/reports/analytics')
            ]),
            new AppMenuItem('Flight', 'Pages.Flight', 'fa fa-plane', '/app/flight'),
            new AppMenuItem('Hotel', 'Pages.Hotel', 'fa fa-hotel', '/app/hotel'),
            new AppMenuItem('Train', 'Pages.Train', 'fa fa-train', '/app/train'),
            new AppMenuItem('Car', 'Pages.Car', 'fa fa-car', '/app/car'),
            new AppMenuItem('Carnet', 'Pages.Carnet.Search', 'fa fa-credit-card', '/app/carnet'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('Airports', 'Pages.Administration.Host.Entities.Airports', 'fa fa-plane', '/app/admin/airports'),
            new AppMenuItem('Aircrafts', 'Pages.Administration.Host.Entities.Aircrafts', 'fa fa-plane', '/app/admin/aircrafts'),
            new AppMenuItem('Airlines', 'Pages.Administration.Host.Entities.Airlines', 'fa fa-plane', '/app/admin/airlines'),
            new AppMenuItem('Stations', 'Pages.Administration.Host.Entities.Stations', 'fa fa-train', '/app/admin/stations'),
            new AppMenuItem('RegionRoot', 'Pages.Administration.Host.Entities.RegionRoots', 'fa fa-building', '/app/admin/regionroots'),
            new AppMenuItem('StructureRoot', 'Pages.Administration.Host.Entities.StructureRoots', 'fa fa-building', '/app/admin/structureroots'),
            new AppMenuItem('CarLocations', 'Pages.Administration.Host.Entities.CarLocations', 'fa fa-car', '/app/admin/carlocations'),
            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [], [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Agencies', 'Pages.Administration.Agencies', 'flaticon-profile-1', '/app/admin/agency/agencies'),
                new AppMenuItem('Agency_Logo', 'Pages.Agency.ManageLogo', 'fa fa-file-image', '/app/admin/agency/manage-logo'),
                new AppMenuItem('Fee_List_Management', 'Pages.Fee', 'flaticon2-zig-zag-line-sign', '/app/main/agencyAdmin/fee'),
                new AppMenuItem('AgencyFee_List_Management', 'Pages.AgencyFee', 'flaticon-settings', '/app/main/agencyAdmin/agencyFee'),
                new AppMenuItem('Train_Settings', 'Pages.Train.Settings', 'flaticon-settings', '/app/admin/train-settings'),
                new AppMenuItem('AirlineRoutes', 'Pages.TravelFusionAirlineRoutes', 'flaticon-add-label-button', '/app/admin/airline-routes'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                new AppMenuItem('UserProfile_CustomFields', 'Pages.UserProfiles.CustomFields', 'fas fa-user-edit' , '/app/user-profile/custom-fields'),
                new AppMenuItem('Trip_CustomFields', 'Pages.Trips.CustomFields', 'fas fa-user-edit' , '/app/trip/custom-fields'),
                new AppMenuItem('Agency_PaymentProfiles', 'Pages.Administration.PaymentProfiles', 'fa fa-credit-card', '/app/admin/payment-profiles'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('EventSync', 'Pages.Administration.EventSync', 'flaticon-refresh', '/app/admin/eventSync'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
                new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'flaticon-interface-8', '/app/admin/dynamic-property'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings'),
                // new AppMenuItem('ProviderSettings', 'Pages.Administration.Tenant.ProviderSettings', 'flaticon-settings', '/app/admin/providerSettings'),
                new AppMenuItem('ProviderProfiles', 'Pages.Administration.Host.ProviderProfiles', 'flaticon-settings', '/app/admin/providerProfiles'),
                new AppMenuItem('TrainSettings', 'Pages.Administration.TrainSettings', 'flaticon-settings', '/app/admin/trainSettings'),
                new AppMenuItem('ManagementFeedAccountant', 'Pages.FeedAccountantSettings', 'flaticon-settings', '/app/admin/feedAccountantSettings'),
                new AppMenuItem('AppSettings', 'Pages.AppSettings', 'flaticon-settings', '/app/admin/AppSettings')
            ])
        ]);
        if (this.featureService.isEnabled('App.UserProfiles') && this._permissionCheckerService.isGranted('Pages.UserProfile')) {
            menu.items.splice(menu.items.length - 2 >= 0 ? menu.items.length - 2 : 0, 0, new AppMenuItem('UserProfiles', 'Pages.UserProfile', 'flaticon-users', '/app/main/user-profiles'));
        }
        if (this.featureService.isEnabled('App.Flight') && this._permissionCheckerService.isGranted('Pages.ValidatingCarrier')) {
            let menuItem = menu.items.find(x => x.name === 'Administration');
            menuItem.items.splice(menuItem.items.length > 4 ? 4 : menuItem.items.length, 0, new AppMenuItem('ValidatingCarrier', 'Pages.ValidatingCarrier', 'fa fa-plane', '/app/admin/validating-carriers'));
        }
        return menu;
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }
        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}