<!-- BEGIN: Horizontal Menu -->
<div ktOffcanvas [options]="offcanvasOptions" [class]="menuWrapperStyle" id="kt_header_menu_wrapper">
    <div [class]="containerClass" class="px-0">
        <div id="kt_header_menu" ktMenu [options]="menuOptions" class="header-menu header-menu-left header-menu-layout-default"
        [ngClass]="isMobileDevice() ? 'header-menu-mobile' : ''">
            <ul class="menu-nav test11">
                <li class="menu-item menu-item-here menu-item-submenu" aria-haspopup="true" *ngIf="isMobileDevice()">
                    <menu-search-bar></menu-search-bar>
                </li>
                <ng-container *ngFor="let item of menu.items">
                    <ng-container *ngIf="item.name" [ngTemplateOutlet]="kt_menu"
                        [ngTemplateOutletContext]="{ item: item, depth: 0 }">
                    </ng-container>
                </ng-container>
                <li class="menu-item menu-item-here menu-item-submenu" aria-haspopup="true" *ngIf="!isMobileDevice()">
                    <menu-search-bar></menu-search-bar>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- END: Horizontal Menu -->



<!-- START: Submenu Level 2 -->
<ng-template #kt_menu let-item="item" let-parentItem="parentItem" let-depth="depth">
    <li attr.aria-haspopup="true" data-menu-toggle="click" class="submenu1 {{ getItemCssClasses(item, parentItem, depth) }}"
        *ngIf="showMenuItem(item)"
        [ngStyle]="{'display': (item.name == 'Administration' && !this._appNavigationService.visibilitaSubMenuAdmin) ? 'none' : 'flex'}"
        routerLinkActive="menu-item-active" [ngClass]="item.level === 1 ? 'submenu2level1' : 'submenu2level2'">

        <!-- if item has submenu -->
        <ng-container *ngIf="item.items.length || item.hasSubMenu">
            <a href="javascript:;" class="{{ getAnchorItemCssClasses(item, parentItem) }}" (click)="getClickOnMenu(item)">
                <ng-container [ngTemplateOutlet]="kt_menu_item_inner"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: (depth + 1) }">
                </ng-container>
                <i *ngIf="!parentItem" class="menu-hor-arrow la la-angle-down ml-2"></i>
                <i *ngIf="parentItem" class="menu-ver-arrow la la-angle-right"></i>
            </a>
        </ng-container>

        <!-- if item hasn't sumbenu -->
        <ng-container *ngIf="!item.items.length && !item.hasSubMenu">
            <a *ngIf="!item.external" [routerLink]="item.route" (click)="getClickOnMenu(item)"
            [queryParams]="item.parameters" class="menu-link">
                <ng-container [ngTemplateOutlet]="kt_menu_item_inner"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: (depth + 1) }">
                </ng-container>
            </a>
            <a *ngIf="item.external" [attr.href]="[item.route]" (click)="getClickOnMenu(item)"
            target="_blank" class="menu-link">
                <ng-container [ngTemplateOutlet]="kt_menu_item_inner"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: (depth + 1) }">
                </ng-container>
            </a>
        </ng-container>
        <!-- if menu item has submenu child then recursively call new menu item component -->
        <ng-container *ngIf="item.items.length && item.items.length > 6 && !isMobileDevice()">
            <div id="menu3Cont">
                <div class="{{ getSubmenuCssClasses(item, parentItem, depth) }} menu-submenu" id="submenu3Cont"
                [ngStyle]='{ width : larghezzaSubMenuEsteso(item) }'>
                    <span class="menu-arrow" [ngClass]="{ 'menu-arrow-adjust': !parentItem }"></span>
                    <div class="menu-subnav" *ngFor="let itemNew6 of item.itemsNew">
                        <ul class="ulSubmenu2 menu-content">
                            <ng-container [ngTemplateOutlet]="kt_menu_sub3"
                                [ngTemplateOutletContext]="{ itemNew6: itemNew6, parentItem: item, depth: (depth + 1) }">
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.items.length && (item.items.length <= 6 || isMobileDevice())">
            <div class="{{ getSubmenuCssClasses(item, parentItem, depth) }}">
                <span class="menu-arrow" [ngClass]="{ 'menu-arrow-adjust': !parentItem }"></span>
                <ul *ngIf="item.items.length" class="menu-subnav ulSubmenu3">
                    <ng-container *ngFor="let child of item.items">
                        <ng-container [ngTemplateOutlet]="kt_menu"
                            [ngTemplateOutletContext]="{ item: child, parentItem: item, depth: (depth + 1) }">
                        </ng-container>
                    </ng-container>
                </ul>
            </div>
        </ng-container>


    </li>
</ng-template>

<!-- item inner -->
<ng-template #kt_menu_item_inner let-item="item" let-parentItem="parentItem">
    <div class="menuElementsCont">
        <!-- if menu item has icon -->
        <i *ngIf="item.icon" class="menu-link-icon" [ngClass]="item.icon"></i>
    
        <ng-container>
            <span class="menu-item-here"></span>
            <!-- menu item title text -->
            <span class="menu-text">
                {{item.name | localize}}
            </span>
        </ng-container>
    </div>
</ng-template>
<!-- END: Submenu Level 2 -->



<!-- START: Submenu Level 3 -->
<ng-template #kt_menu_sub3 let-itemNew6="itemNew6" let-parentItem="parentItem" let-depth="depth">
    <div *ngFor="let itemNew of itemNew6">
        <li class="menu-item {{ getItemCssClasses(itemNew, parentItem, depth) }}" *ngIf="showMenuItem(itemNew)">
            <ng-container *ngIf="itemNew.items.length == 0">
                <a *ngIf="!itemNew.external" [routerLink]="itemNew.route" (click)="getClickOnMenu(itemNew)"
                [queryParams]="itemNew.parameters" class="menu-link">
                    <ng-container [ngTemplateOutlet]="kt_menu_item_inner_sub"
                        [ngTemplateOutletContext]="{ itemNew: itemNew, parentItem: parentItem, depth: (depth + 1) }">
                    </ng-container>
                </a>
                <a *ngIf="itemNew.external" [attr.href]="[itemNew.route]" (click)="getClickOnMenu(itemNew)"
                target="_blank" class="menu-link">
                    <ng-container [ngTemplateOutlet]="kt_menu_item_inner_sub"
                        [ngTemplateOutletContext]="{ itemNew: itemNew, parentItem: parentItem, depth: (depth + 1) }">
                    </ng-container>
                </a>
            </ng-container>

            <ng-container *ngIf="itemNew.items.length > 0">
                <div class="{{ getSubmenuCssClasses(itemNew, parentItem, depth) }}">
                    <span class="menu-arrow" [ngClass]="{ 'menu-arrow-adjust': !parentItem }"></span>
                    <ul *ngIf="itemNew.items.length" class="menu-subnav ulSubmenu3">
                        <ng-container *ngFor="let child of itemNew.items">

                            <ng-container [ngTemplateOutlet]="kt_menu"
                                [ngTemplateOutletContext]="{ item: child, parentItem: itemNew, depth: (depth + 1) }">
                            </ng-container>

                        </ng-container>
                    </ul>
                </div>
            </ng-container>
        </li>
        <!-- 
        <li attr.aria-haspopup="true" data-menu-toggle="click" class="submenu3 {{ getItemCssClasses(itemNew, parentItem, depth) }}"
            routerLinkActive="menu-item-active">

            <ng-container *ngIf="itemNew.items.length">
                <a href="javascript:;" class="{{ getAnchorItemCssClasses(itemNew, parentItem) }}">
                    <ng-container [ngTemplateOutlet]="kt_menu_item_inner_sub"
                        [ngTemplateOutletContext]="{ itemNew: itemNew, parentItem: parentItem, depth: (depth + 1) }">
                    </ng-container>
                    <i *ngIf="itemNew.items.length && !parentItem" class="menu-hor-arrow la la-angle-down ml-2 mt-2"></i>
                    <i *ngIf="itemNew.items.length && parentItem" class="menu-ver-arrow la la-angle-right"></i>
                </a>
            </ng-container>

            <ng-container *ngIf="!itemNew.items.length">
                <a *ngIf="!itemNew.external" [routerLink]="itemNew.route"
                [queryParams]="itemNew.parameters" class="menu-link">
                    <ng-container [ngTemplateOutlet]="kt_menu_item_inner_sub"
                        [ngTemplateOutletContext]="{ itemNew: itemNew, parentItem: parentItem, depth: (depth + 1) }">
                    </ng-container>
                </a>
                <a *ngIf="itemNew.external" [attr.href]="[itemNew.route]"
                target="_blank" class="menu-link">
                    <ng-container [ngTemplateOutlet]="kt_menu_item_inner_sub"
                        [ngTemplateOutletContext]="{ itemNew: itemNew, parentItem: parentItem, depth: (depth + 1) }">
                    </ng-container>
                </a>
            </ng-container>

            <ng-container *ngIf="itemNew.items.length">
                <div class="{{ getSubmenuCssClasses(itemNew, parentItem, depth) }}">
                    <span class="menu-arrow" [ngClass]="{ 'menu-arrow-adjust': !parentItem }"></span>
                    <ul *ngIf="itemNew.items.length" class="menu-subnav ulSubmenu3">
                        <ng-container *ngFor="let child of itemNew.items">

                            <ng-container [ngTemplateOutlet]="kt_menu"
                                [ngTemplateOutletContext]="{ item: child, parentItem: itemNew, depth: (depth + 1) }">
                            </ng-container>

                        </ng-container>
                    </ul>
                </div>
            </ng-container>
        </li>
        -->
    </div>
</ng-template>


<!-- item inner -->
<ng-template #kt_menu_item_inner_sub let-itemNew="itemNew" let-parentItem="parentItem">
    <div class="menuElementsCont">
        <!-- if menu item has icon -->
        <i *ngIf="itemNew.icon" class="menu-link-icon" [ngClass]="itemNew.icon"></i>
        <ng-container>
            <span class="menu-item-here"></span>
            <!-- menu item title text -->
            <span class="menu-text-submenu">
                {{itemNew.name | localize}}
            </span>
        </ng-container>
    </div>
</ng-template>
<!-- END: Submenu Level 3 -->