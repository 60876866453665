import { animate, state, style, transition, trigger } from '@angular/animations';
import { ElementRef, Injector, TemplateRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, LegacyTextOnlySnackBar as TextOnlySnackBar } from '@angular/material/legacy-snack-bar';

import { AppConsts } from '@shared/AppConsts';
import {
	FileDownloadLinkDTO,
	GetNewsOutput,
	HostDashboardServiceProxy,
	NewsDTO,
	ProfileServiceProxy,
	TenantDashboardServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { AbpSessionService } from 'abp-ng2-module';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { filter, finalize } from 'rxjs/operators';
import { WidgetComponentBaseComponent } from '../widget-component-base';

@Component({
	selector: 'app-widget-news',
	templateUrl: './widget-news.component.html',
	styleUrls: ['./widget-news.component.scss'],
	animations: [
		trigger('addLinkInput', [
			state('in', style({ opacity: 1 })),
			transition('void => *', [style({ opacity: 0, transform: 'translateY(100%)' }), animate(200)]),
			transition('* => void', [animate(200, style({ opacity: 0, transform: 'translateY(100%)' }))]),
		]),
		trigger('addTitleInput', [
			state('in', style({ opacity: 1 })),
			transition('void => *', [style({ opacity: 0, transform: 'translateY(-100%)' }), animate(200)]),
			transition('* => void', [animate(200, style({ opacity: 0, transform: 'translateY(-100%)' }))]),
		]),
		trigger('fadeTransition', [
			transition(':enter', [style({ opacity: 0 }), animate(500, style({ opacity: 1 }))]),
			transition(':leave', [animate(500, style({ opacity: 0 }))]),
		]),
		trigger('addPost', [
			state('in', style({ opacity: 1 })),
			transition('void => *', [style({ opacity: 0, transform: 'translateY(-100%)' }), animate(300)]),
			transition('* => void', [animate(300, style({ opacity: 0, transform: 'translateY(-100%)' }))]),
		]),
	],
})
export class WidgetNewsComponent extends WidgetComponentBaseComponent implements OnInit {
	@ViewChild('showNewsModal', { static: true }) showNewsModal: ModalDirective;
	@ViewChild('widgetParent')
	widgetParent: ElementRef;
	@ViewChild('templateModal') templateModal: TemplateRef<any>;
	@ViewChild('deleteTemplateModal') deleteTemplateModal: TemplateRef<any>;

	constructor(
		injector: Injector,
		private tenantDashboardService: TenantDashboardServiceProxy,
		private hostDashboardService: HostDashboardServiceProxy,
		private _sessionService: AbpSessionService,
		private _profileService: ProfileServiceProxy,
		private matSnackBar: MatSnackBar,
		private fb: UntypedFormBuilder,
		private modalService: BsModalService
	) {
		super(injector);
		this.form = fb.group({
			message: [null, [Validators.required]],
		});

		const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
		this.addLinkFormGroup = fb.group({
			url: ['', [Validators.required, Validators.pattern(reg)]],
			alias: [''],
		});
		this.carouselResponsiveOptions = [
			{
				breakpoint: '1024px',
				numVisible: 3,
				numScroll: 1,
			},
			{
				breakpoint: '768px',
				numVisible: 2,
				numScroll: 1,
			},
			{
				breakpoint: '560px',
				numVisible: 1,
				numScroll: 1,
			},
		];
	}

	widgetNews: GetNewsOutput = new GetNewsOutput();
	title: string = '';
	isLoading: boolean = false;
	addLinkEnabled: boolean = false;
	isAddingNews: boolean = false;
	removedNews: NewsDTO = null;
	removedNewsIndex: number = -1;
	openedSnackBar: MatSnackBarRef<TextOnlySnackBar> = null;
	selectedNews: NewsDTO = null;
	newsToDelete: NewsDTO = null;
	modalRef: BsModalRef;
	deleteModalRef: BsModalRef;
	showNewsDialog: boolean = false;
	showNewsAddForm: boolean = false;
	profilePicture: string = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
	userName: string = '';
	uploadedImage: string = '';
	uploadedLink: FileDownloadLinkDTO = null;
	carouselResponsiveOptions = [];

	form: UntypedFormGroup = new UntypedFormGroup({});
	addLinkFormGroup: UntypedFormGroup = new UntypedFormGroup({});

	ngOnInit(): void {
		this.isLoading = true;
		this.getUserName();
		this.getProfilePicture();
		this.getNews();
	}

	/**
	 * Funzione che crea ed aggiunge una nuova news, basandosi sullo stato ed informazioni delle proprietà del componente.
	 */
	addNews(n: NewsDTO = null) {
		let news = new NewsDTO();
		if (n != null) {
			news = n;
		} else {
			news.authorName = this.userName;
			news.newsDate = moment();
			news.link = this.uploadedLink;
			news.frontImageSource = this.uploadedImage;
			news.title = this.title;
			news.userId = this.getUserId();
			news.text = this.addPostForm['message'].value;
		}
		this.isAddingNews = true;

		if (this.getTenantId() != null) {
			this.tenantDashboardService
				.setNews(news)
				.pipe(
					finalize(() => {
						this.uploadedImage = '';
						this.title = '';
						this.uploadedLink = null;
						this.isAddingNews = false;
						this.showNewsAddForm = false;
						this.form.reset();
						this.addLinkFormGroup.reset();
						this.getNews();
					})
				)
				.subscribe(() => {});
		} else {
			this.hostDashboardService
				.setNews(news)
				.pipe(
					finalize(() => {
						this.uploadedImage = '';
						this.title = '';
						this.uploadedLink = null;
						this.isAddingNews = false;
						this.showNewsAddForm = false;
						this.form.reset();
						this.addLinkFormGroup.reset();
						this.getNews();
					})
				)
				.subscribe(() => {});
		}
	}

	removeNews() {
		if (this.getTenantId() != null) {
			this.tenantDashboardService
				.removeNews(this.newsToDelete.newsID)
				.pipe(
					finalize(() => {
						this.getNews();
						this.openSnackBarForUndoDeleting();
						this.removedNews = this.newsToDelete;
						this.removedNewsIndex = this.widgetNews.elements.indexOf(this.newsToDelete);
						this.newsToDelete = null;
					})
				)
				.subscribe(() => {});
		} else {
			this.hostDashboardService
				.removeNews(this.newsToDelete.newsID)
				.pipe(
					finalize(() => {
						this.getNews();
						this.openSnackBarForUndoDeleting();
						this.newsToDelete = null;
					})
				)
				.subscribe(() => {});
		}
	}

	getNews() {
		this.isLoading = true;
		if (this.getTenantId() != null) {
			this.tenantDashboardService
				.getNews()
				.pipe(
					filter((value) => !isEmpty(value)),
					finalize(() => {
						this.isLoading = false;
					})
				)
				.subscribe((ret) => {
					this.widgetNews.elements = ret.elements;
				});
		} else {
			this.hostDashboardService
				.getNews()
				.pipe(
					filter((value) => !isEmpty(value)),
					finalize(() => {
						this.isLoading = false;
					})
				)
				.subscribe((ret) => {
					this.widgetNews.elements = ret.elements;
				});
		}
	}

	getTenantId() {
		return this._sessionService.tenantId;
	}

	getUserId() {
		return this._sessionService.userId;
	}

	getUserName(): void {
		this._profileService.getCurrentUserProfileForEdit().subscribe((r) => {
			this.userName = r.userName;
		});
	}
	formatText(text: string, cutText: boolean = true) {
		let ret = text;
		if (cutText && ret.length > 26) {
			ret = `${ret.substring(0, 25)}...`;
		}
		return ret;
	}

	getProfilePicture(): void {
		this._profileService.getProfilePicture().subscribe((result) => {
			if (result && result.profilePicture) {
				this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
			}
		});
	}

	//#region Getter dei controlli delle form
	get addPostForm() {
		return this.form.controls;
	}
	get addLinkForm() {
		return this.addLinkFormGroup.controls;
	}
	//#endregion

	/**
	 * Funzione che valuta la promise file reader per ottenere, dall'immagine caricata dall'utente, una stringa formattata (DataUrl).
	 * @param event Oggetto rappresentante lo stato dell'input.
	 */
	getImageAsDataUrl(event): void {
		const fileType = event.target.files[0].type.split('/');
		const fileDim = event.target.files[0].size / 1024 / 1024;
		if (fileType[0] !== 'image') {
			//Se il file caricato non è di tipo immagine
			this.message.error(this.l('Widget_News_Wrong_File_Type'), this.l('Common_Error_Call'));
		} else if (fileDim > 1) {
			//Se la dimensione del file caricato è maggiore di 1mb
			this.message.error(this.l('Widget_News_Image_Too_Big'), this.l('Common_Error_Call'));
		} else {
			var promise = this.pFileReader(event.target.files[0]);
			promise.then((ret) => {
				this.uploadedImage = ret as string;
			});
		}
	}

	/**
	 * Funzione che ritorna una promise la quale, al suo interno, gestisce in modo asincrono la lettura del file passato tramite parametro.
	 * @param file File caricato (in questo caso, sarà sempre un'immagine).
	 * @returns Promise che sarà valutata.
	 */
	pFileReader(file) {
		return new Promise((resolve, _) => {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = function (evt) {
				if (evt.target.readyState == FileReader.DONE) {
					resolve(evt.target.result as string);
				}
			};
		});
	}

	/**
	 * Funzione che aggiunge un link alla notizia che si vuole inserire.
	 * @param link Link da aggiungere alla news.
	 * @param alias Possibile alias associato al link.
	 */
	addLink(link: string, alias: string) {
		this.uploadedLink = new FileDownloadLinkDTO({
			link: link,
			alias: alias,
			addedByHost: this.getTenantId() == null ? true : false,
		});
		this.triggerAddMode();
	}

	triggerAddMode() {
		this.addLinkFormGroup.reset();
		this.addLinkEnabled = !this.addLinkEnabled;
	}

	triggerPostAddMode() {
		this.form.reset();
		this.uploadedImage = null;
		this.addLinkFormGroup.reset();
		this.addLinkEnabled = false;
		this.showNewsAddForm = !this.showNewsAddForm;
		this.title = null;
	}

	getUploadedLink() {
		if (this.uploadedLink.alias) {
			return this.uploadedLink.alias;
		} else {
			return this.uploadedLink.link;
		}
	}

	openSnackBarForUndoDeleting() {
		//#region se viene rimosso una news
		this.openedSnackBar = this.matSnackBar.open(this.l('News_Widget_News_Deleted'), this.l('Link_Widget_Undo'), {
			panelClass: 'success-snackbar',
			duration: 5000,
		});
		this.openedSnackBar.onAction().subscribe(() => {
			this.widgetNews.elements.splice(this.removedNewsIndex, 0, this.removedNews); // Reinserimento del link precedentemente cancellato, nel suo indice.
			this.addNews(this.removedNews);
			this.openedSnackBar.dismiss();
		});
		//#endregion
	}

	openNewsDialog(news: NewsDTO) {
		this.modalRef = this.modalService.show(this.templateModal, Object.assign({}, { class: 'gray modal-dialog-centered' }));
		this.selectedNews = news;
	}

	closeNewsDialog() {
		this.modalRef.hide();
	}

	openNewsDeleteDialog(news: NewsDTO) {
		this.deleteModalRef = this.modalService.show(this.deleteTemplateModal, Object.assign({}, { class: 'gray modal-dialog-centered' }));
		this.newsToDelete = news;
	}

	closeNewsDeleteDialog() {
		this.deleteModalRef.hide();
	}
}
