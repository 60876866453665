import { PermissionCheckerService } from 'abp-ng2-module';
import { Component, Injector, OnInit, Output, ViewEncapsulation, EventEmitter, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppMenu } from './app-menu';
import { AppNavigationService } from './app-navigation.service';
import * as objectPath from 'object-path';
import { filter } from 'rxjs/operators';
import { MenuOptions } from '@metronic/app/core/_base/layout/directives/menu.directive';
import { OffcanvasOptions } from '@metronic/app/core/_base/layout/directives/offcanvas.directive';
import { ThemeAssetContributorFactory } from '@shared/helpers/ThemeAssetContributorFactory';

@Component({
    selector: 'top-bar-menu',
    templateUrl: './top-bar-menu.component.html',
    styleUrls: ['./top-bar-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TopBarMenuComponent extends AppComponentBase implements OnInit {
    @Output() submenuOn: EventEmitter<any> = new EventEmitter<any>();
    @Input() menuLevel: number = 0;

    menu: AppMenu = null;
    currentRouteUrl: any = '';
    menuDepth: 0;
    menuWrapperStyle = '';

    menuOptions: MenuOptions = {
        submenu: {
            desktop: 'dropdown',
            tablet: 'accordion',
            mobile: 'accordion',
        },

        accordion: {
            expandAll: false,
        }
    };

    offcanvasOptions: OffcanvasOptions = {
        overlay: true,
        baseClass: 'header-menu-wrapper',
        closeBy: 'kt_header_menu_mobile_close_btn',
        toggleBy: 'kt_header_mobile_toggle'
    };

    constructor(
        injector: Injector,
        private router: Router,
        public permission: PermissionCheckerService,
        public _appNavigationService: AppNavigationService) {
        super(injector);
    }

    ngOnInit() {
        this.menu = this._appNavigationService.getMenu(this.menuLevel);
        this.currentRouteUrl = this.router.url;
        this.menuWrapperStyle = ThemeAssetContributorFactory.getCurrent().getMenuWrapperStyle();

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(event => {
                this.currentRouteUrl = this.router.url;
            });
            
        if (this.menuLevel == 2) {
            this.subMenuItems();
        } else {
            this.menuItemsComplete()
        }
    }

    showMenuItem(menuItem): boolean {
        return this._appNavigationService.showMenuItem(menuItem);
    }

    getItemCssClasses(item, parentItem, depth) {
        let isRootLevel = item && !parentItem;

        let cssClasses = 'menu-item menu-item-rel';

        if (objectPath.get(item, 'items.length')) {
            cssClasses += ' menu-item-submenu';
        }

        if (objectPath.get(item, 'icon-only')) {
            cssClasses += ' menu-item-icon-only';
        }

        if (this.isMenuItemIsActive(item)) {
            cssClasses += ' menu-item-active';
        }

        /* if (item.items.length) {
            cssClasses += ' menu-item-submenu menu-item-rel';
            if (depth && depth === 1) {
                cssClasses += ' menu-item-open-dropdown';
            }
        } else if (item.items.length) {
            if (depth && depth >= 1) {
                cssClasses += ' menu-item-submenu';
            } else {
                cssClasses += ' menu-item-rel';
            }
        } */

        if (item.items.length || item.hasSubMenu) {
            cssClasses += ' menu-item-submenu menu-item-rel';
            if (depth && depth === 1) {
                cssClasses += ' menu-item-open-dropdown';
            } else if (depth && depth >= 1) {
                cssClasses += ' menu-item-submenu';
            } else {
                cssClasses += ' menu-item-rel';
            }
        }

        return cssClasses;
    }

    getAnchorItemCssClasses(item, parentItem): string {
        let isRootLevel = item && !parentItem;
        let cssClasses = 'menu-link';

        if (isRootLevel || item.items.length || item.hasSubMenu) {
            cssClasses += ' menu-toggle';
        }

        return cssClasses;
    }

    getSubmenuCssClasses(item, parentItem, depth): string {
        let cssClasses = 'menu-submenu';
        if (this.menuLevel == 1) {
            cssClasses += ' menu-submenu-' + (depth >= 1 ? 'right' : 'left');
        } else {
            cssClasses += ' menu-submenu-center';
        }
        return cssClasses;
    }

    isMenuItemIsActive(item): boolean {
        if (item?.items.length) {
            return this.isMenuRootItemIsActive(item);
        }

        if (!item.route) {
            return false;
        }

        return this.currentRouteUrl.replace(/\/$/, '') === item.route.replace(/\/$/, '');
    }

    isMenuRootItemIsActive(item): boolean {
        if (item.items) {
            for (const subItem of item.items) {
                if (this.isMenuItemIsActive(subItem)) {
                    return true;
                }
            }
        }

        return false;
    }

    getItemAttrSubmenuToggle(menuItem, parentItem, depth) {
        if (depth && depth >= 1) {
            return 'hover';
        } else {
            return 'click';
        }
    }

    isMobileDevice(): any {
        return KTUtil.isMobileDevice();
    }

    getClickOnMenu(item: any) {
        this.submenuOn.emit(item);
    }

    subMenuItems() {
        for (const item of this.menu.items) {
            item.level = this.menuLevel;
            let itemsSub = []
            let itemsSubPermission = []
            if(this.showMenuItem(item) && item.items.length === 0) {
                this._appNavigationService.visibilitaSubMenuAdmin = true
            }
            if (item.items.length > 0 && item.items.length <= 6) {
                for (const subItem of item.items) {
                    if (this.showMenuItem(subItem)) {
                        itemsSub.push([ subItem ]);
                        itemsSubPermission.push(subItem);
                        this._appNavigationService.visibilitaSubMenuAdmin = true
                    }
                }
                // itemsSub.push([ Object.assign({}, item.items)]);
            } else if (item.items.length > 0 && item.items.length > 6) {
                let index1 = 0
                for (const subItem of item.items) {
                    if (this.showMenuItem(subItem)) {
                        // creo sottogruppi da 6 voci menù ognuno
                        let diviso = index1 / 6;
                        // check se numero intero
                        if (diviso % 1 == 0) {
                            itemsSub.push([ subItem ]);
                        } else {
                            let numeroArray: number = parseInt((diviso + '').charAt(0));
                            itemsSub[numeroArray].push(subItem);
                        }
                        itemsSubPermission.push(subItem);
                        index1++
                        this._appNavigationService.visibilitaSubMenuAdmin= true
                    }
                }
            }
            item.itemsNew = itemsSub
            item.itemsPermission = itemsSubPermission
        }
    }

    larghezzaSubMenuEsteso(item) {
        // 221px larghezza max consentita colonna menù
        let sequenza = '100%'
        if (item.itemsNew) {
            if (item.itemsNew.length) {
                sequenza = `${item.itemsNew.length * 221}px`;
            }
        }
        return sequenza
    }

    menuItemsComplete(){
        for (const item of this.menu.items) {
            let itemsSubPermission = []
            item.level = this.menuLevel;
            if (item.items.length > 0 && item.items.length <= 6) {
                for (const subItem of item.items) {
                    if (this.showMenuItem(subItem)) {
                        itemsSubPermission.push(subItem);
                    }
                }
            }
            item.itemsPermission = itemsSubPermission
        }
    }
}
