import { Injector, Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { AppConsts } from '@shared/AppConsts';
import { ToggleOptions } from '@metronic/app/core/_base/layout/directives/toggle.directive';
import { DOCUMENT } from '@angular/common';
import { OffcanvasOptions } from '@metronic/app/core/_base/layout/directives/offcanvas.directive';

@Component({
    templateUrl: './theme7-layout.component.html',
    selector: 'theme7-layout',
    animations: [appModuleAnimation()],
    styleUrls: ['./theme7-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class Theme7LayoutComponent extends ThemesLayoutBaseComponent implements OnInit {
    public showSubMenu = false;
    public clickInHeader = false;

    userMenuToggleOptions: ToggleOptions = {
        target: this.document.body,
        targetState: 'topbar-mobile-on',
        toggleState: 'active'
    };
    menuCanvasOptions: OffcanvasOptions = {
        baseClass: 'aside',
        overlay: true,
        closeBy: 'kt_aside_close_btn',
        toggleBy: 'kt_aside_mobile_toggle'
    };

    asideToggler;

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document
    ) {
        super(injector);
    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        this.defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-dark-2.svg';
        this.asideToggler = new KTOffcanvas(this.document.getElementById('kt_header_navs'), {
            overlay: true,
            baseClass: 'header-navs',
            toggleBy: 'kt_header_mobile_toggle'
        });
    }

    submenuOnF(item: any) {
        if (item.name == "Administration" && item.level == 1) {
            this.showSubMenu = !this.showSubMenu;
        } else if (item.name != "Administration" && item.level != 2) {
            this.showSubMenu = false;
        }
        /*else if (item.items.length > 0 || item.hasSubMenu && item.level != 2) {
            this.showSubMenu = false;
        }*/
    }

    clickOnKtWrapper() {
        if (!this.clickInHeader) {
            this.showSubMenu = false;
        }
        this.clickInHeader = false;
    }
    clickOnKtHeader() {
        this.clickInHeader = true;
    }
    
    checkMenuLevel() {
        if (this.isMobileDevice() == true) {
            return 0
        } else {
            return 2
        }
    }
}
