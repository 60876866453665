import { AvailabilityRequestMulticityDTO, AvailabilityStructuresRequestDto } from '@shared/service-proxies/service-proxies';
import { DatePipe } from '@angular/common';
import { MyCarAvailabilityPagedRequestDto } from '@app/shared/models/car.models';
import { MyTrainAvailabilityRequestDTO } from '@app/shared/models/client-train-models';
// import { AvailabilityPackageRateRequestDto } from '@app/api/models';

export function flightDescriptionFromRequest(request: AvailabilityRequestMulticityDTO, datePipe: DatePipe): string {
    let requestText = '';
    request.legs.forEach((leg, index) => {
        requestText += `${leg.originAirportCode} - ${ leg.destinationAirportCode } ${datePipe.transform(leg.departureDate.toString(), 'd LLL yy' )}`;
        if (index !== request.legs.length - 1) {
            requestText += ' | ';
        }
    });

    return requestText;
}

export function hotelDescriptionFromRequest(request: AvailabilityStructuresRequestDto, datePipe: DatePipe, isPackageRate: boolean = false): string {
    let requestText = '';
    if (request.destination && request.checkinDate && request.checkoutDate) {
        requestText = request.destination;
        if(!isPackageRate){
            requestText += ' - ' +
            datePipe.transform(request.checkinDate.toString(), 'd LLL yy') +
            ' - ' +
            datePipe.transform(request.checkoutDate.toString(), 'd LLL yy');
        }
    }

    return requestText;
}

export function trainDescriptionFromRequest(request: MyTrainAvailabilityRequestDTO, datePipe: DatePipe): string {
    const returnText = request.returnDate
        ? `<br/>${request.destinationCityName} - ${
              request.originCityName
          } ${datePipe.transform(request.returnDate.toString(), 'd LLL yy')} ${
              request.returnRangeStartTime
          }`
        : '';

    return `${request.originCityName} - ${
        request.destinationCityName
    } ${datePipe.transform(request.departureDate.toString(), 'd LLL yy')} ${
        request.departureRangeStartTime
    } ${returnText}`;
}

export function carDescriptionFromRequest(request: MyCarAvailabilityPagedRequestDto, datePipe: DatePipe): string {

        const locationsEqual = request.pickUpLocation.id === request.dropOffLocation.id;
        const dropOffDateTime = `${datePipe.transform(request.dropOffDate.toString(), 'd LLL yy')} - ${request.dropOffTime}`;

        const pickUpText = `${request.pickUpLocation.name} ${datePipe.transform(request.pickUpDate.toString(), 'd LLL yy')} - ${request.pickUpTime}${locationsEqual ? ` | ${dropOffDateTime}` : ''}`;

        const dropOffText = `${request.dropOffLocation.name} ${dropOffDateTime}`;

        if (!locationsEqual) {
            return `<p>${pickUpText}</p> <p>${dropOffText}</p>`;
        } else {
            return `<p>${pickUpText}`;
        }
}


