/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AjaxResponseOfPagedResultDtoOfTenantListDto } from '../models/ajax-response-of-paged-result-dto-of-tenant-list-dto';
import { CreateTenantInput } from '../models/create-tenant-input';
import { EntityDto } from '../models/entity-dto';
import { GetTenantFeaturesEditOutput } from '../models/get-tenant-features-edit-output';
import { TenantEditDto } from '../models/tenant-edit-dto';
import { UpdateTenantFeaturesInput } from '../models/update-tenant-features-input';

@Injectable({
  providedIn: 'root',
})
export class TenantService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTenants
   */
  static readonly GetTenantsPath = '/api/services/app/Tenant/GetTenants';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenants()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenants$Response(params?: {
    Filter?: string;
    SubscriptionEndDateStart?: string;
    SubscriptionEndDateEnd?: string;
    CreationDateStart?: string;
    CreationDateEnd?: string;
    EditionId?: number;
    EditionIdSpecified?: boolean;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<StrictHttpResponse<AjaxResponseOfPagedResultDtoOfTenantListDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.GetTenantsPath, 'get');
    if (params) {
      rb.query('Filter', params.Filter, {});
      rb.query('SubscriptionEndDateStart', params.SubscriptionEndDateStart, {});
      rb.query('SubscriptionEndDateEnd', params.SubscriptionEndDateEnd, {});
      rb.query('CreationDateStart', params.CreationDateStart, {});
      rb.query('CreationDateEnd', params.CreationDateEnd, {});
      rb.query('EditionId', params.EditionId, {});
      rb.query('EditionIdSpecified', params.EditionIdSpecified, {});
      rb.query('Sorting', params.Sorting, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.query('SkipCount', params.SkipCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfPagedResultDtoOfTenantListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTenants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenants(params?: {
    Filter?: string;
    SubscriptionEndDateStart?: string;
    SubscriptionEndDateEnd?: string;
    CreationDateStart?: string;
    CreationDateEnd?: string;
    EditionId?: number;
    EditionIdSpecified?: boolean;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<AjaxResponseOfPagedResultDtoOfTenantListDto> {

    return this.getTenants$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfPagedResultDtoOfTenantListDto>) => r.body as AjaxResponseOfPagedResultDtoOfTenantListDto)
    );
  }

  /**
   * Path part for operation createTenant
   */
  static readonly CreateTenantPath = '/api/services/app/Tenant/CreateTenant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTenant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTenant$Response(params?: {
    body?: CreateTenantInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.CreateTenantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTenant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTenant(params?: {
    body?: CreateTenantInput
  }): Observable<void> {

    return this.createTenant$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTenant$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createTenant$Xml$Response(params?: {
    body?: CreateTenantInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.CreateTenantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTenant$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createTenant$Xml(params?: {
    body?: CreateTenantInput
  }): Observable<void> {

    return this.createTenant$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getTenantForEdit
   */
  static readonly GetTenantForEditPath = '/api/services/app/Tenant/GetTenantForEdit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantForEdit$Response(params?: {
    Id?: number;
  }): Observable<StrictHttpResponse<TenantEditDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.GetTenantForEditPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantEditDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTenantForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantForEdit(params?: {
    Id?: number;
  }): Observable<TenantEditDto> {

    return this.getTenantForEdit$Response(params).pipe(
      map((r: StrictHttpResponse<TenantEditDto>) => r.body as TenantEditDto)
    );
  }

  /**
   * Path part for operation updateTenant
   */
  static readonly UpdateTenantPath = '/api/services/app/Tenant/UpdateTenant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTenant$Response(params?: {
    body?: TenantEditDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.UpdateTenantPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTenant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTenant(params?: {
    body?: TenantEditDto
  }): Observable<void> {

    return this.updateTenant$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenant$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateTenant$Xml$Response(params?: {
    body?: TenantEditDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.UpdateTenantPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTenant$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateTenant$Xml(params?: {
    body?: TenantEditDto
  }): Observable<void> {

    return this.updateTenant$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteTenant
   */
  static readonly DeleteTenantPath = '/api/services/app/Tenant/DeleteTenant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenant$Response(params?: {
    Id?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.DeleteTenantPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenant(params?: {
    Id?: number;
  }): Observable<void> {

    return this.deleteTenant$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getTenantFeaturesForEdit
   */
  static readonly GetTenantFeaturesForEditPath = '/api/services/app/Tenant/GetTenantFeaturesForEdit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantFeaturesForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantFeaturesForEdit$Response(params?: {
    Id?: number;
  }): Observable<StrictHttpResponse<GetTenantFeaturesEditOutput>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.GetTenantFeaturesForEditPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetTenantFeaturesEditOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTenantFeaturesForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantFeaturesForEdit(params?: {
    Id?: number;
  }): Observable<GetTenantFeaturesEditOutput> {

    return this.getTenantFeaturesForEdit$Response(params).pipe(
      map((r: StrictHttpResponse<GetTenantFeaturesEditOutput>) => r.body as GetTenantFeaturesEditOutput)
    );
  }

  /**
   * Path part for operation updateTenantFeatures
   */
  static readonly UpdateTenantFeaturesPath = '/api/services/app/Tenant/UpdateTenantFeatures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenantFeatures()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTenantFeatures$Response(params?: {
    body?: UpdateTenantFeaturesInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.UpdateTenantFeaturesPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTenantFeatures$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTenantFeatures(params?: {
    body?: UpdateTenantFeaturesInput
  }): Observable<void> {

    return this.updateTenantFeatures$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenantFeatures$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateTenantFeatures$Xml$Response(params?: {
    body?: UpdateTenantFeaturesInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.UpdateTenantFeaturesPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTenantFeatures$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateTenantFeatures$Xml(params?: {
    body?: UpdateTenantFeaturesInput
  }): Observable<void> {

    return this.updateTenantFeatures$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation resetTenantSpecificFeatures
   */
  static readonly ResetTenantSpecificFeaturesPath = '/api/services/app/Tenant/ResetTenantSpecificFeatures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetTenantSpecificFeatures()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resetTenantSpecificFeatures$Response(params?: {
    body?: EntityDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.ResetTenantSpecificFeaturesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetTenantSpecificFeatures$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resetTenantSpecificFeatures(params?: {
    body?: EntityDto
  }): Observable<void> {

    return this.resetTenantSpecificFeatures$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetTenantSpecificFeatures$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  resetTenantSpecificFeatures$Xml$Response(params?: {
    body?: EntityDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.ResetTenantSpecificFeaturesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetTenantSpecificFeatures$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  resetTenantSpecificFeatures$Xml(params?: {
    body?: EntityDto
  }): Observable<void> {

    return this.resetTenantSpecificFeatures$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation unlockTenantAdmin
   */
  static readonly UnlockTenantAdminPath = '/api/services/app/Tenant/UnlockTenantAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlockTenantAdmin()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unlockTenantAdmin$Response(params?: {
    body?: EntityDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.UnlockTenantAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unlockTenantAdmin$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unlockTenantAdmin(params?: {
    body?: EntityDto
  }): Observable<void> {

    return this.unlockTenantAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlockTenantAdmin$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  unlockTenantAdmin$Xml$Response(params?: {
    body?: EntityDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantService.UnlockTenantAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unlockTenantAdmin$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  unlockTenantAdmin$Xml(params?: {
    body?: EntityDto
  }): Observable<void> {

    return this.unlockTenantAdmin$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
