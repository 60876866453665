import { Injectable } from '@angular/core';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppConsts } from '@shared/AppConsts';
import {
	AccountServiceProxy,
	ImpersonateInput,
	ImpersonateOutput,
	IsTenantAvailableInput,
	IsTenantAvailableOutput,
} from '@shared/service-proxies/service-proxies';
import { AbpSessionService } from 'abp-ng2-module';

@Injectable()
export class ImpersonationService {
	constructor(
		private _accountService: AccountServiceProxy,
		private _authService: AppAuthService,
		private abpSessionService: AbpSessionService
	) {}

	impersonate(userId: number, tenantId?: number): void {
		const input = new ImpersonateInput();
		input.userId = userId;
		input.tenantId = tenantId;

		this._accountService.impersonate(input).subscribe((result: ImpersonateOutput) => {
			this._authService.logout(false);
			const model = {
				isHost: !this.abpSessionService.tenantId,
				tenantName: result.tenancyName,
				username: result.userName
			};

			const id = encodeURIComponent(btoa(JSON.stringify(model)));

			if (!this.abpSessionService.tenantId) {
				this.changeTenant(result.tenancyName, id);
			} else {
				this.goToLogin(id);
			}
		});
	}

	private goToLogin(id: string) {
		window.open(`${AppConsts.loginUrl}/prova/index.html?id=${id}`);
	}

	changeTenant(tenancyName: string, id: string): void {
		let input = new IsTenantAvailableInput();
		input.tenancyName = tenancyName;

		this._accountService.isTenantAvailable(input).subscribe((result: IsTenantAvailableOutput) => {
			abp.multiTenancy.setTenantIdCookie(result.tenantId);

			this.goToLogin(id);
		});
	}
}
