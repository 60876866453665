/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AjaxResponseOfBoolean } from '../models/ajax-response-of-boolean';
import { AjaxResponseOfListOfSelectItemOfInt32String } from '../models/ajax-response-of-list-of-select-item-of-int-32-string';
import { AjaxResponseOfListOfUserListDto } from '../models/ajax-response-of-list-of-user-list-dto';
import { CreateOrUpdateUserInput } from '../models/create-or-update-user-input';
import { EntityDtoOfInt64 } from '../models/entity-dto-of-int-64';
import { FileDto } from '../models/file-dto';
import { GetUserForEditOutput } from '../models/get-user-for-edit-output';
import { GetUserPermissionsForEditOutput } from '../models/get-user-permissions-for-edit-output';
import { PagedResultDtoOfUserListDto } from '../models/paged-result-dto-of-user-list-dto';
import { UpdateUserPermissionsInput } from '../models/update-user-permissions-input';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUsersAutocomplete
   */
  static readonly GetUsersAutocompletePath = '/api/services/app/User/GetUsersAutocomplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersAutocomplete()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersAutocomplete$Response(params?: {
    name?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfUserListDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUsersAutocompletePath, 'get');
    if (params) {
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfUserListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsersAutocomplete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersAutocomplete(params?: {
    name?: string;
  }): Observable<AjaxResponseOfListOfUserListDto> {

    return this.getUsersAutocomplete$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfUserListDto>) => r.body as AjaxResponseOfListOfUserListDto)
    );
  }

  /**
   * Path part for operation getUsers_1
   */
  static readonly GetUsers_1Path = '/api/services/app/User/GetUsers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers_1$Response(params?: {
    Filter?: string;
    Permissions?: Array<string>;
    Role?: number;
    OnlyLockedUsers?: boolean;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<StrictHttpResponse<PagedResultDtoOfUserListDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUsers_1Path, 'get');
    if (params) {
      rb.query('Filter', params.Filter, {});
      rb.query('Permissions', params.Permissions, {});
      rb.query('Role', params.Role, {});
      rb.query('OnlyLockedUsers', params.OnlyLockedUsers, {});
      rb.query('Sorting', params.Sorting, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.query('SkipCount', params.SkipCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedResultDtoOfUserListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsers_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers_1(params?: {
    Filter?: string;
    Permissions?: Array<string>;
    Role?: number;
    OnlyLockedUsers?: boolean;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<PagedResultDtoOfUserListDto> {

    return this.getUsers_1$Response(params).pipe(
      map((r: StrictHttpResponse<PagedResultDtoOfUserListDto>) => r.body as PagedResultDtoOfUserListDto)
    );
  }

  /**
   * Path part for operation getUsersToExcel
   */
  static readonly GetUsersToExcelPath = '/api/services/app/User/GetUsersToExcel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersToExcel()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersToExcel$Response(params?: {
    Filter?: string;
    Permissions?: Array<string>;
    Role?: number;
    OnlyLockedUsers?: boolean;
    Sorting?: string;
  }): Observable<StrictHttpResponse<FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUsersToExcelPath, 'get');
    if (params) {
      rb.query('Filter', params.Filter, {});
      rb.query('Permissions', params.Permissions, {});
      rb.query('Role', params.Role, {});
      rb.query('OnlyLockedUsers', params.OnlyLockedUsers, {});
      rb.query('Sorting', params.Sorting, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsersToExcel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersToExcel(params?: {
    Filter?: string;
    Permissions?: Array<string>;
    Role?: number;
    OnlyLockedUsers?: boolean;
    Sorting?: string;
  }): Observable<FileDto> {

    return this.getUsersToExcel$Response(params).pipe(
      map((r: StrictHttpResponse<FileDto>) => r.body as FileDto)
    );
  }

  /**
   * Path part for operation getUserForEdit
   */
  static readonly GetUserForEditPath = '/api/services/app/User/GetUserForEdit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserForEdit$Response(params?: {
    Id?: number;
  }): Observable<StrictHttpResponse<GetUserForEditOutput>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUserForEditPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetUserForEditOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserForEdit(params?: {
    Id?: number;
  }): Observable<GetUserForEditOutput> {

    return this.getUserForEdit$Response(params).pipe(
      map((r: StrictHttpResponse<GetUserForEditOutput>) => r.body as GetUserForEditOutput)
    );
  }

  /**
   * Path part for operation getUserPermissionsForEdit
   */
  static readonly GetUserPermissionsForEditPath = '/api/services/app/User/GetUserPermissionsForEdit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserPermissionsForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPermissionsForEdit$Response(params?: {
    Id?: number;
  }): Observable<StrictHttpResponse<GetUserPermissionsForEditOutput>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUserPermissionsForEditPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetUserPermissionsForEditOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserPermissionsForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPermissionsForEdit(params?: {
    Id?: number;
  }): Observable<GetUserPermissionsForEditOutput> {

    return this.getUserPermissionsForEdit$Response(params).pipe(
      map((r: StrictHttpResponse<GetUserPermissionsForEditOutput>) => r.body as GetUserPermissionsForEditOutput)
    );
  }

  /**
   * Path part for operation resetUserSpecificPermissions
   */
  static readonly ResetUserSpecificPermissionsPath = '/api/services/app/User/ResetUserSpecificPermissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetUserSpecificPermissions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resetUserSpecificPermissions$Response(params?: {
    body?: EntityDtoOfInt64
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ResetUserSpecificPermissionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetUserSpecificPermissions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resetUserSpecificPermissions(params?: {
    body?: EntityDtoOfInt64
  }): Observable<void> {

    return this.resetUserSpecificPermissions$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetUserSpecificPermissions$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  resetUserSpecificPermissions$Xml$Response(params?: {
    body?: EntityDtoOfInt64
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ResetUserSpecificPermissionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetUserSpecificPermissions$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  resetUserSpecificPermissions$Xml(params?: {
    body?: EntityDtoOfInt64
  }): Observable<void> {

    return this.resetUserSpecificPermissions$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateUserPermissions
   */
  static readonly UpdateUserPermissionsPath = '/api/services/app/User/UpdateUserPermissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserPermissions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserPermissions$Response(params?: {
    body?: UpdateUserPermissionsInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UpdateUserPermissionsPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserPermissions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserPermissions(params?: {
    body?: UpdateUserPermissionsInput
  }): Observable<void> {

    return this.updateUserPermissions$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserPermissions$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateUserPermissions$Xml$Response(params?: {
    body?: UpdateUserPermissionsInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UpdateUserPermissionsPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserPermissions$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateUserPermissions$Xml(params?: {
    body?: UpdateUserPermissionsInput
  }): Observable<void> {

    return this.updateUserPermissions$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createOrUpdateUser
   */
  static readonly CreateOrUpdateUserPath = '/api/services/app/User/CreateOrUpdateUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateUser$Response(params?: {
    body?: CreateOrUpdateUserInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.CreateOrUpdateUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateUser(params?: {
    body?: CreateOrUpdateUserInput
  }): Observable<void> {

    return this.createOrUpdateUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateUser$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateUser$Xml$Response(params?: {
    body?: CreateOrUpdateUserInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.CreateOrUpdateUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateUser$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateUser$Xml(params?: {
    body?: CreateOrUpdateUserInput
  }): Observable<void> {

    return this.createOrUpdateUser$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteUser
   */
  static readonly DeleteUserPath = '/api/services/app/User/DeleteUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params?: {
    Id?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.DeleteUserPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params?: {
    Id?: number;
  }): Observable<void> {

    return this.deleteUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation unlockUser
   */
  static readonly UnlockUserPath = '/api/services/app/User/UnlockUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlockUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unlockUser$Response(params?: {
    body?: EntityDtoOfInt64
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UnlockUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unlockUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unlockUser(params?: {
    body?: EntityDtoOfInt64
  }): Observable<void> {

    return this.unlockUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlockUser$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  unlockUser$Xml$Response(params?: {
    body?: EntityDtoOfInt64
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UnlockUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unlockUser$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  unlockUser$Xml(params?: {
    body?: EntityDtoOfInt64
  }): Observable<void> {

    return this.unlockUser$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation checkIfUserIsAdmin
   */
  static readonly CheckIfUserIsAdminPath = '/api/services/app/User/CheckIfUserIsAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkIfUserIsAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfUserIsAdmin$Response(params?: {
  }): Observable<StrictHttpResponse<AjaxResponseOfBoolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.CheckIfUserIsAdminPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfBoolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkIfUserIsAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfUserIsAdmin(params?: {
  }): Observable<AjaxResponseOfBoolean> {

    return this.checkIfUserIsAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfBoolean>) => r.body as AjaxResponseOfBoolean)
    );
  }

  /**
   * Path part for operation getRoles_1
   */
  static readonly GetRoles_1Path = '/api/services/app/User/GetRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoles_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles_1$Response(params?: {
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfSelectItemOfInt32String>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetRoles_1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfSelectItemOfInt32String>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRoles_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles_1(params?: {
  }): Observable<AjaxResponseOfListOfSelectItemOfInt32String> {

    return this.getRoles_1$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfSelectItemOfInt32String>) => r.body as AjaxResponseOfListOfSelectItemOfInt32String)
    );
  }

}
