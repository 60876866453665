import { PermissionCheckerService, RefreshTokenService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { Observable } from 'rxjs/internal/Observable';
import { of, Subject, tap } from 'rxjs';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
        private _refreshTokenService: RefreshTokenService
    ) { }

    canActivateInternal(data: any, state: RouterStateSnapshot): Observable<boolean> {
        if (UrlHelper.isInstallUrl(location.href)) {
            return of(true);
        }

        if (!this._sessionService.user) {
            this._router.navigate(['/account/login']);
            return of(false);
        }

        if (!data || !data['permission']) {
            return of(true);
        }

        if (this._permissionChecker.isGranted(data['permission'])) {
            return of(true);
        }

        this._router.navigate([this.selectBestRoute()]);
        return of(false);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateInternal(route.data, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canLoad(route: any): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateInternal(route.data, null);
    }

    selectBestRoute(): string {

        if (!this._sessionService.user) {
            return '/account/login';
        }
        
        if (this._permissionChecker.isGranted('Pages.Tenants')) {
            return '/app/admin/tenants';
        }

        // if (this._permissionChecker.isGranted('Pages.Administration.Users')) {
        //     return '/app/admin/users';
        // }

        // if (this._permissionChecker.isGranted('Pages.Trips.FullAssistedBooking')) {
        //     return '';
        // }

        // if (this._permissionChecker.isGranted('Pages.Trips.Search')) {
        //     return '/app/trip/init';
        // }

        // return '/app/main/home';
    }
}
