import { IThemeAssetContributor } from '@app/shared/layout/themes/ThemeAssetContributor';
import { ThemeHelper } from '@app/shared/layout/themes/ThemeHelper';
import { Theme1ThemeAssetContributor } from '@app/shared/layout/themes/theme1/Theme1ThemeAssetContributor';
import { Theme7ThemeAssetContributor } from '@app/shared/layout/themes/theme7/Theme7ThemeAssetContributor';

export class ThemeAssetContributorFactory {
    static getCurrent(): IThemeAssetContributor {
        let theme = ThemeHelper.getTheme();

        if (theme === 'theme1' || theme === 'default') {
            return new Theme1ThemeAssetContributor();
        }

        if (theme === 'theme7') {
            return new Theme7ThemeAssetContributor();
        }

        return null;
    }
}
